<template>
  <v-row>
    <v-col cols="12">
      <title-and-content-row :title="$t('general.details')">
        <template v-slot:content>
          <div>
            <strong>{{ detailData.name }}</strong>
            <div class="text-caption grey--text text--darken-2">
              {{ $t('acquisition.received.createdAt') }}:
              {{ getDateTimeValue(detailData.createdAt, 'long') }}
            </div>
          </div>
        </template>
        <template v-slot:action>
          <v-btn
            outlined
            small
            rounded
            :input-value="expandHistory"
            :disabled="!detailData.documentId"
            active-class="primary--text"
            @click="expandHistory = !expandHistory"
          >
            {{ $t('integration.detail.process') }}
          </v-btn>
        </template>
      </title-and-content-row>
      <v-divider />
      <title-and-content-row
        :title="$t('integration.pipeline')"
        :content="detailData.pipeline"
      />
      <v-divider />
      <title-and-content-row :title="$t('general.document')">
        <template
          v-if="document.docType"
          v-slot:content
        >
          <div>
            <strong>{{ $t(`documentCategories.${document.docType}`) }}</strong>
            <div>{{ document.docNum }}</div>
          </div>
        </template>
        <template v-slot:action>
          <v-btn
            v-if="detailData.documentId && documentCategory"
            outlined
            rounded
            small
            :to="{
              name: documentRoutes[$router.currentRoute.name],
              params: {
                workspaceId: $route.params.workspaceId,
                documentCategory: documentCategory,
                routeDetailId: detailData.documentId
              }
            }"
          >
            {{ $t('general.preview') }}
          </v-btn>
        </template>
      </title-and-content-row>
      <simple-side-sheet
        v-if="detailData.id"
        :expand-sheet.sync="expandHistory"
      >
        <template v-slot:title>
          {{ $t('integration.detail.process') }}
        </template>
        <template v-slot:content>
          <message-history :params="messagesParams" />
        </template>
      </simple-side-sheet>
    </v-col>
  </v-row>
</template>

<script>
import api from '@/api/modules/documents'
import { mapGetters } from 'vuex'
import { getDateTimeValue } from '@/mixins/dates'
import TitleAndContentRow from '@/components/layout/TitleAndContentRow'
import SimpleSideSheet from '@/components/side_sheets/SimpleSideSheet'
import MessageHistory from '@/components/messages/MessageHistory'

export default {
  name: 'IntegrationDetailOverview',
  components: {
    TitleAndContentRow,
    SimpleSideSheet,
    MessageHistory
  },
  mixins: [
    getDateTimeValue
  ],
  props: {
    detailData: { type: Object, default: () => { return {} } },
    loadingData: { type: Boolean, default: false }
  },
  data () {
    return {
      document: {},
      expandHistory: false,
      integrationStatusIcon: {
        sent: this.$vuetify.icons.values.check,
        pending: this.$vuetify.icons.values.integrationSend
      },
      integrationStatusColor: {
        sent: 'success--text',
        pending: 'grey--text'
      },
      documentRoutes: {
        IntegrationFilesDetails: 'DocumentsInboxDetails',
        ReceivedDetails: 'DocumentsSentDetails'
      }
    }
  },
  computed: {
    ...mapGetters([
      'getDocumentsGroup'
    ]),
    documentCategory () {
      return this.getDocumentsGroup(this.document.docType)
    },
    messagesParams () {
      return this.detailData
        ? [
          { id: this.detailData.documentId, content: 'document' },
          { id: this.detailData.id, content: 'integrationFile' }
        ]
        : []
    }
  },
  watch: {
    detailData: {
      immediate: true,
      deep: true,
      async handler (data) {
        if (data.documentId) {
          this.loading = true
          try {
            const response = await api.getDocumentDetailById(data.documentId)
            this.document = response || {}
          } catch (error) {
            return false
          } finally {
            this.loading = false
          }
        }
      }
    }
  }
}
</script>
