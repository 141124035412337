<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <details-toolbar
          :title="$t('integration.detail.cardInfoTitle')"
          :detail-data="detailData"
        >
          <template v-slot:chip>
            <status-chip :status="detailData.status" />
          </template>
        </details-toolbar>
        <card-layout
          enable-actions
          disable-title
        >
          <template v-slot:content>
            <integration-detail-overview
              :detail-data="detailData"
              :loading-data="loadingData"
            />
          </template>
          <template v-slot:actions>
            <v-btn
              color="primary"
              text
              rounded
              small
              target="_blank"
              :disabled="!detailData.url"
              :href="detailData.url"
            >
              <v-icon left>
                {{ $vuetify.icons.values.download }}
              </v-icon>
              {{ $t('integration.detail.downloadLink') }}
            </v-btn>
          </template>
        </card-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/api/modules/integration'
import { getDateTimeValue } from '@/mixins/dates'
import DetailsToolbar from '@/components/toolbars/DetailsToolbar'
import CardLayout from '@/components/layout/CardLayout'
import StatusChip from '@/components/chips/StatusChip'
import IntegrationDetailOverview from '@/components/details/integration/Overview'

export default {
  name: 'IntegrationDetails',
  components: {
    DetailsToolbar,
    CardLayout,
    StatusChip,
    IntegrationDetailOverview
  },
  mixins: [getDateTimeValue],
  data () {
    return {
      detailData: {},
      loadingData: false
    }
  },
  mounted () {
    this.getDetailData()
  },
  methods: {
    async getDetailData () {
      this.loadingData = true
      const result = await api.fetchDetail()
      this.detailData = result
      this.loadingData = false
    }
  }
}
</script>
