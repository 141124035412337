var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('title-and-content-row',{attrs:{"title":_vm.$t('general.details')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c('strong',[_vm._v(_vm._s(_vm.detailData.name))]),_c('div',{staticClass:"text-caption grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('acquisition.received.createdAt'))+": "+_vm._s(_vm.getDateTimeValue(_vm.detailData.createdAt, 'long'))+" ")])])]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"outlined":"","small":"","rounded":"","input-value":_vm.expandHistory,"disabled":!_vm.detailData.documentId,"active-class":"primary--text"},on:{"click":function($event){_vm.expandHistory = !_vm.expandHistory}}},[_vm._v(" "+_vm._s(_vm.$t('integration.detail.process'))+" ")])]},proxy:true}])}),_c('v-divider'),_c('title-and-content-row',{attrs:{"title":_vm.$t('integration.pipeline'),"content":_vm.detailData.pipeline}}),_c('v-divider'),_c('title-and-content-row',{attrs:{"title":_vm.$t('general.document')},scopedSlots:_vm._u([(_vm.document.docType)?{key:"content",fn:function(){return [_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t(("documentCategories." + (_vm.document.docType)))))]),_c('div',[_vm._v(_vm._s(_vm.document.docNum))])])]},proxy:true}:null,{key:"action",fn:function(){return [(_vm.detailData.documentId && _vm.documentCategory)?_c('v-btn',{attrs:{"outlined":"","rounded":"","small":"","to":{
            name: _vm.documentRoutes[_vm.$router.currentRoute.name],
            params: {
              workspaceId: _vm.$route.params.workspaceId,
              documentCategory: _vm.documentCategory,
              routeDetailId: _vm.detailData.documentId
            }
          }}},[_vm._v(" "+_vm._s(_vm.$t('general.preview'))+" ")]):_vm._e()]},proxy:true}],null,true)}),(_vm.detailData.id)?_c('simple-side-sheet',{attrs:{"expand-sheet":_vm.expandHistory},on:{"update:expandSheet":function($event){_vm.expandHistory=$event},"update:expand-sheet":function($event){_vm.expandHistory=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('integration.detail.process'))+" ")]},proxy:true},{key:"content",fn:function(){return [_c('message-history',{attrs:{"params":_vm.messagesParams}})]},proxy:true}],null,false,958883805)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }